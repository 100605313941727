/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { useHistory } from "react-router";
import { JobAddDialog } from "../../../../../app/modules/Jobs/pages/Jobs/customer-add-dialog/JobAddDialog";

export function QuickActionsDropdown() {
  const bgImage = toAbsoluteUrl("/media/misc/bg-2.jpg");
  const uiService = useHtmlClassService();
  const [show, setShow] = useState(false);
  const [openJobModal, setOpenJobModal] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };
  const history = useHistory();

  return (
    <>
      <div
        id="kt_header_menu "
        className={`header-menu header-menu-mobile quick_action`}
      >
        <Dropdown
          drop="down"
          show={show}
          onMouseEnter={showDropdown}
          onMouseLeave={hideDropdown}
          // alignleft
        >
          <ul className={`menu-nav h-100 pt-0 pb-0`}>
            <li className={`menu-item menu-item-rel`}>
              <a className="menu-link menu-toggle p-0" id="tour_quick_action">
                <Dropdown.Toggle
                  as={DropdownTopbarItemToggler}
                  id="kt_quick_actions_panel_toggle"
                >
                  {/*begin::1 Level*/}

                  <span className="menu-text" style={{ padding: "0.65rem" }}>
                    Quick Actions
                  </span>
                </Dropdown.Toggle>
              </a>
            </li>
          </ul>

          <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
            {/* begin: Head */}
            {/* <div
              className="d-flex flex-column align-items-center justify-content-center pt-10 pb-10 bgi-size-cover bgi-no-repeat rounded-top"
              style={{ backgroundImage: `url(${bgImage})` }}
            >
              <h3 className="text-white font-weight-bold font-size-5 mb-0">
                Quick Actions
              </h3>
            </div> */}
            {/* end: Head */}
            <div className="row row-paddingless">
              <div className="col-6">
                <a
                  // href="#"
                  onClick={() => setOpenJobModal(true)}
                  id="quick_new_job"
                  className="d-block py-10 px-5 text-center bg-hover-light border-right border-bottom"
                >
                  <span className="svg-icon svg-icon-3x svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Tools/Hummer.svg")}
                    ></SVG>
                  </span>
                  <span className="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">
                    New Job
                  </span>
                </a>
              </div>

              <div className="col-6">
                <a
                  // href="#"
                  onClick={() => history.push("/quotes/newquotes")}
                  className="d-block py-10 px-5 text-center bg-hover-light border-bottom"
                >
                  {" "}
                  <span className="svg-icon svg-icon-3x svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Files/File-done.svg"
                      )}
                    ></SVG>
                  </span>
                  <span className="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1">
                    New Quote
                  </span>
                </a>
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <JobAddDialog
        show={openJobModal}
        onHide={() => setOpenJobModal(false)}
        type="quickAction"
        transferJob={false}
      />
    </>
  );
}
