import axios from "axios";
import { LOGIN_URL } from "../../../Auth/_redux/authCrud";

export const JOBS_URL = "https://www.api.sendatradie.com/jobs";
export const COMPANY_URL = "https://www.api.sendatradie.com/company";
export const USER_URL = "https://www.api.sendatradie.com/users";
export const GET_JOBS = "https://www.api.sendatradie.com/jobs/getJobs";
export const TEMPLATE_URL = "https://www.api.sendatradie.com/template";
export const CLIENT_URL = "https://www.api.sendatradie.com/client";
export const STRIPE = "https://api.stripe.com/";
export const SETTINGS_URL = "https://www.api.sendatradie.com/settings";
export const MESSAGES_URL = "https://www.api.sendatradie.com/messages";

// CREATE =>  POST: add a new customer to the server
export function createCustomer(customer) {
  return axios.post(JOBS_URL, { customer });
}
export function fetchLastInvoiceAndReceipt(queryParams) {
  return axios.post(`${JOBS_URL}/fetchLastInvoiceAndReceipt`, queryParams);
}

export function getJobStatus(queryParams) {
  return axios.post(`${JOBS_URL}/fetchLastInvoiceAndReceipt`, queryParams);
}

// READ
export function getAllCustomers() {
  return axios.get(JOBS_URL);
}

export function getCustomerById(customerId) {
  return axios.get(`${JOBS_URL}/getJob/${customerId}`);
}

export function getJobById(id) {
  //hideconsole.log("--------", id);
  return axios.get(`${JOBS_URL}/getJob/${id}`);
}

export function getJobsStatus() {
  return axios.get(`${JOBS_URL}/status`);
}

export function getJobsType() {
  return axios.get(`${JOBS_URL}/getJobsType`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function getJobs(queryParams) {
  return axios.post(`${JOBS_URL}/find`, { queryParams });
}

export function fetchWebLeads(queryParams) {
  return axios.post(`${CLIENT_URL}/fetchWebLeads`, { queryParams });
}

export function findTech(queryParams) {
  return axios.post(`${USER_URL}/getWorkersLocation`, { queryParams });
}

// UPDATE => PUT: update the customer on the server
export function updateCustomer(customer) {
  return axios.put(`${JOBS_URL}/${customer.id}`, { customer });
}

export function addJob(job) {
  return axios.post(`${JOBS_URL}/add`, { job });
}

export function editJob(job) {
  ////hideconsole.log(values.appointment_time);
  // const UTCappointment_time = new Date(job.appointment_time).toISOString();
  // //hideconsole.log(UTCappointment_time);
  return axios.post(`${JOBS_URL}/edit/${job.id}`, { job });
}

// UPDATE Status
export function updateStatusForCustomers(ids, status) {
  return axios.post(`${JOBS_URL}/updateStatusForCustomers`, {
    ids,
    status,
  });
}

// Add files
export function addfiles(files, id) {
  //hideconsole.log("addfiles", id);
  const formData = new FormData();

  const keyForFile = files.file ? Object.keys(files.file) : [];
  //hideconsole.log(keyForFile);
  keyForFile &&
    keyForFile.length &&
    keyForFile.map((key) => {
      formData.append("file", files.file[key]);
    });

  if (id) {
    return axios.post(`${JOBS_URL}/addfiles/${id}`, formData);
  } else {
    return axios.post(`${JOBS_URL}/addfiles`, formData);
  }
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId) {
  return axios.delete(`${JOBS_URL}/${customerId}`);
}

// DELETE Customers by ids
export function deleteCustomers(ids) {
  return axios.post(`${CLIENT_URL}/deleteCustomers`, { ids });
}

// Company URL
export function getWorkers() {
  return axios.get(`${COMPANY_URL}/getWorkers`);
}

export function getWorkerOptions() {
  return axios.get(`${COMPANY_URL}/getWorkerOptions`);
}

export function getCompanyLeadsList() {
  return axios.get(`${COMPANY_URL}/getCompanyLeadsList`);
}

export function paybuy() {
  return axios.get(`${COMPANY_URL}/payby`);
}

export function tools() {
  return axios.get(`${COMPANY_URL}/tools`);
}

export function items() {
  return axios.get(`${COMPANY_URL}/items`);
}

export function additem(item) {
  return axios.post(`${COMPANY_URL}/additem`, item);
}

export function fees() {
  return axios.get(`${COMPANY_URL}/fees`);
}

// submit data for invoices

export function submitInvoice(values, id) {
  //for checking all data is correct we can delete it later it is for testing purpose
  // for (var pair of values.entries()) {
  //     //hideconsole.log(pair[0] + ", " + pair[1]);
  //   }
  return axios.post(`${JOBS_URL}/sendInvoice/${id}`, values);
}
export function submiteditInvoice(values, id) {
  //for checking all data is correct we can delete it later it is for testing purpose
  // for (var pair of values.entries()) {
  //     //hideconsole.log(pair[0] + ", " + pair[1]);
  //   }
  return axios.post(`${JOBS_URL}/edit/sendInvoice/${id}`, values);
}

// team section apis

export function getPaymentType() {
  return axios.get(`${COMPANY_URL}/getPaymentType`);
}

export function getToolOptions() {
  return axios.get(`${COMPANY_URL}/getToolOptions`);
}

export function getToolOptionsAll() {
  return axios.get(`${COMPANY_URL}/getToolOptionsAll`);
}

export function fetchStringId(data) {
  return axios.post(`${JOBS_URL}/fetchStringId`, data);
}

export function getUserGroup() {
  return axios.get(`${COMPANY_URL}/getUserGroup`);
}
export function getUserSkills() {
  return axios.get(`${JOBS_URL}/getJobsType`);
}
export function saveNewCustomer(values) {
  return axios.post(`${COMPANY_URL}/addUser`, values);
}
export function editCustomer(values) {
  return axios.post(`${COMPANY_URL}/editUser`, values);
}

export function getUserDetails(id) {
  return axios.get(`${COMPANY_URL}/getUser/${id}`);
}
export function saveNewCustomerCommision(data) {
  return axios.post(`${COMPANY_URL}/addUserCommision`, data);
}
export function saveNewTeam(data) {
  return axios.post(`${COMPANY_URL}/addNewTeam`, data);
}
export function editUserTeam(data, id) {
  return axios.post(`${COMPANY_URL}/editTeam/${id}`, data);
}

//plans
export function getSubScriptionPlan() {
  return axios.get(`${COMPANY_URL}/getPack`);
}
export function getSMSSubScriptionPlan() {
  return axios.get(`${COMPANY_URL}/getSMSPack`);
}

export function getCallSubScriptionPlan() {
  return axios.get(`${COMPANY_URL}/getCallPack`);
}

// Stripe --- paymentID
export function createCustomerId() {
  return axios.get(`${COMPANY_URL}/paymentID`);
}
export function createPOstCustomerId(data) {
  return axios.post(`${COMPANY_URL}/paymentID`, data);
}

// SMS template API
export function PostSMSReviewTemplate(data) {
  return axios.post(`${TEMPLATE_URL}/smsReviewTemplate`, data);
}
export function PostsmsJobTemplate(data) {
  return axios.post(`${TEMPLATE_URL}/editSMSJobTemplate`, data);
}
export function PostsmsAppointmentTemplate(data) {
  return axios.post(`${TEMPLATE_URL}/editSMSAppointmentTemplate`, data);
}
export function getSMSReviewTemplate() {
  return axios.get(`${TEMPLATE_URL}/smsReview`);
}
export function getJobAppintmentTemplate() {
  return axios.get(`${TEMPLATE_URL}/smsJob`);
}
export function getEmailTemplateList() {
  return axios.get(`${TEMPLATE_URL}/emailTemplateList `);
}
export function getEmailTemplateForText() {
  return axios.get(`${TEMPLATE_URL}/emailTemplate `);
}
export function postEmailTemplate(data) {
  return axios.post(`${TEMPLATE_URL}/editCompanyEmailTemplate`, data);
}

// Manage Leads
export function getPaymentTypeData() {
  return axios.get(`${COMPANY_URL}/getPaymentType`);
}
export function PostCreateLeads(data) {
  return axios.post(`${COMPANY_URL}/addLead`, data);
}
export function PostEditLeads(id, data) {
  return axios.post(`${COMPANY_URL}/editLead/${id}`, data);
}
export function getParticularLead(id) {
  return axios.get(`${COMPANY_URL}/getLeadsList/${id}`);
}
export function deleteParticularLead(data) {
  return axios.post(`${COMPANY_URL}/deleteLeads`, data);
}

export function postcreateQuotes(data) {
  return axios.post(`${CLIENT_URL}/createQuotes`, data);
}
export function posteditQuotes(data, id) {
  return axios.post(`${CLIENT_URL}/editQuotes/${id}`, data);
}
export function requestQuote(data) {
  return axios.post(`${CLIENT_URL}/quotesRequest`, data);
}

// delete File
export function deleteFile(data, id) {
  return axios.post(`${JOBS_URL}/deleteFile/${id}`, { data: data });
}

export function getCountryISO() {
  return axios.get(`${COMPANY_URL}/getCountryISO`);
}

export function changeLeadsStatus(id, checked) {
  return axios.post(`${COMPANY_URL}/changeLeadsStatus/${id}`, checked);
}

export function changeJobTypeStatus(id, checked) {
  return axios.post(`${SETTINGS_URL}/changeJobTypeStatus/${id}`, checked);
}

export function getJobsValidation() {
  return axios.get(`${SETTINGS_URL}/getJobsValidation`);
}

export function changeStockTemplateStatus(id, checked) {
  return axios.post(`${SETTINGS_URL}/changeStockTemplateStatus/${id}`, checked);
}

export function addStockTemplate(data) {
  return axios.post(`${SETTINGS_URL}/addStockTemplate`, data);
}

export function GetStockTemplate(id) {
  return axios.get(`${SETTINGS_URL}/getStockTemplate/${id}`);
}

export function editStockTemplate(id, data) {
  return axios.post(`${SETTINGS_URL}/editStockTemplate/${id}`, data);
}

export function getTemplateOptions() {
  return axios.get(`${COMPANY_URL}/getTemplateOptions`);
}

export function addTemplatesToStocks(data) {
  return axios.post(`${COMPANY_URL}/addTemplatesToStocks`, data);
}

export function deleteReceipt(id) {
  return axios.post(`${JOBS_URL}/deleteReceipt/${id}`);
}

export function getTermCondition() {
  return axios.get(`${SETTINGS_URL}/getTermCondition`);
}

export function getQuote(id) {
  return axios.get(`${CLIENT_URL}/getQuote/${id}`);
}

export function getCompanyTaxRate() {
  return axios.get(`${COMPANY_URL}/getTaxRate`);
}

export function setCompanyTaxRate(newRate) {
  return axios.post(`${COMPANY_URL}/setTaxRate`, newRate);
}

export function saveInvoiceSettings(settings) {
  return axios.post(`${SETTINGS_URL}/saveInvoiceSettings`, settings);
}

export function getInvoiceSettings() {
  return axios.get(`${SETTINGS_URL}/getInvoiceSettingsV2`);
}

export function twilioOutboundCall(number) {
  return axios.post(`${MESSAGES_URL}/twilioOutBoundCall`, number);
}

export function getCompanyNumbering() {
  return axios.get(`${SETTINGS_URL}/getCompanyNumbering`);
}

export function setCompanyNumbering(settings) {
  return axios.post(`${SETTINGS_URL}/setCompanyNumbering`, settings);
}

export function saveClientsImport(data) {
  return axios.post(`${CLIENT_URL}/saveClientsImport`, data);
}

export function getNotificationSettings() {
  return axios.get(`${SETTINGS_URL}/getNotificationSettings`);
}

export function getNotificationSetting(id) {
  return axios.get(`${SETTINGS_URL}/getNotificationSetting/${id}`);
}

export function saveNotificationSettings(data, id) {
  return axios.post(`${SETTINGS_URL}/saveNotificationSettings/${id}`, data);
}

export function changeNotificationStatus(id, checked) {
  return axios.post(`${SETTINGS_URL}/changeNotificationStatus/${id}`, checked);
}

export function deleteStockTemplate(id) {
  return axios.post(`${SETTINGS_URL}/deleteStockTemplates`, { ids: id });
}

export function getUserLimit() {
  return axios.get(`${COMPANY_URL}/getUserLimit`);
}

export function stripeCreateCustomer(data) {
  return axios.post(`${COMPANY_URL}/stripeCreateCustomer`, data);
}

export function stripeCreatePaymentMethod(data) {
  return axios.post(`${COMPANY_URL}/stripeCreatePaymentMethod`, data);
}

export function stripeCreateToken(data) {
  return axios.post(`${COMPANY_URL}/stripeCreateToken`, data);
}

export function getBankingDetails() {
  return axios.get(`${SETTINGS_URL}/getBankingDetails`);
}

export function setBankingDetails(data) {
  return axios.post(`${SETTINGS_URL}/setBankingDetails`, data);
}

export function saveJobSignature(data) {
  return axios.post(`${JOBS_URL}/saveJobSignature`, data);
}

export function updateJobSignature(data) {
  return axios.post(`${JOBS_URL}/updateJobSignature`, data);
}

export function getJobSignature(id) {
  return axios.get(`${JOBS_URL}/getJobSignature/${id}`);
}

export function getJobSignatureClient(id) {
  return axios.get(`${JOBS_URL}/getJobSignatureClient/${id}`);
}

export function deleteJobSignature(id) {
  return axios.get(`${JOBS_URL}/deleteJobSignature/${id}`);
}

export function getStoragePlans() {
  return axios.get(`${COMPANY_URL}/getStoragePlans`);
}

export function xeroGetPayrollCalendars() {
  return axios.get(`${USER_URL}/xeroGetPayrollCalendars`);
}

export function xeroAddEmployees(data) {
  return axios.post(`${USER_URL}/xeroAddEmployees`, data);
}

export function xeroGetEmployees() {
  return axios.get(`${USER_URL}/xeroGetEmployees`);
}

export function xeroLinkEmployee(data) {
  return axios.post(`${USER_URL}/xeroLinkEmployee`, data);
}

export function xeroImportEmployees(data) {
  return axios.post(`${USER_URL}/xeroImportEmployees`, data);
}

export function getAllUsers() {
  return axios.get(`${COMPANY_URL}/getAllUsers`);
}

export function addAutomation(data) {
  return axios.post(`${MESSAGES_URL}/addAutomation`, data);
}

export function getAutomations() {
  return axios.get(`${MESSAGES_URL}/getAutomations`);
}

export function editAutomation(data) {
  return axios.post(`${MESSAGES_URL}/editAutomation`, data);
}

export function changeAutomationStatus(data) {
  return axios.post(`${MESSAGES_URL}/changeAutomationStatus`, data);
}

export function setPlanRefill(data) {
  return axios.post(`${COMPANY_URL}/setPlanRefill`, data);
}

export function getChangePlanPreview(data) {
  return axios.post(`${COMPANY_URL}/getChangePlanPreview`, data);
}

export function getDefaultPeriod() {
  return axios.get(`${COMPANY_URL}/getDefaultPeriod`);
}

export function setDefaultPeriod(data) {
  return axios.post(`${COMPANY_URL}/setDefaultPeriod`, data);
}

export function getApiKey() {
  return axios.get(`${SETTINGS_URL}/getApiKey`);
}

export function applyDiscountCode(data) {
  return axios.post(`${COMPANY_URL}/getDiscount`, data);
}

export function getOnboardingProgress() {
  return axios.get(`${SETTINGS_URL}/getOnboardingProgress`);
}

export function setGoogleCalendar(data) {
  return axios.post(`${JOBS_URL}/setGoogleCalendar`, data);
}

export function getCalendarData() {
  return axios.get(`${JOBS_URL}/getCalendarData`);
}

export function selectGoogleCalendars(data) {
  return axios.post(`${JOBS_URL}/selectGoogleCalendars`, data);
}

export function recordLogout() {
  return axios.get(`${LOGIN_URL}/recordLogout`);
}

export function fetchWorkersCalendar(data) {
  return axios.post(`${JOBS_URL}/fetchWorkersCalendar`, data);
}

export function extractJobFromFile(data) {
  return axios.post(`${JOBS_URL}/extractJobFromFile`, data);
}

export function writeEmailTemplate(data) {
  return axios.post(`${JOBS_URL}/writeEmailTemplate`, data);
}

export function generateReviewReply(data) {
  return axios.post(`${JOBS_URL}/generateReviewReply`, data);
}

/**
 * Fetch microsite templates
 * @returns
 */
export function getMicrositeTemplates() {
  return axios.get(`${COMPANY_URL}/micrositeTemplates`);
}

/**
 * Fetch default microsite template
 * @returns
 */
export function getDefaultMicrositeTemplate(data) {
  return axios.post(`${COMPANY_URL}/fetchDefaultMicrositeTemplate`, data);
}

export function unsubscribe(data) {
  return axios.post(`${COMPANY_URL}/unsubscribe`, data);
}

export function resubscribe(data) {
  return axios.post(`${COMPANY_URL}/resubscribe`, data);
}

export function newPaymentMethod(data) {
  return axios.post(`${COMPANY_URL}/newPaymentMethod`, data);
}
