import React, { Component } from "react";

import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as requestFromServer from "./../../Jobs/_redux/customers/customersCrud.js";
import * as auth from "./../_redux/authRedux.js";

class Logout extends Component {
  componentDidMount() {
    requestFromServer
      .recordLogout()
      .then((res) => {
        this.props.logout();
      })
      .catch((err) => {
        if (err.response?.status == 401) {
          this.props.logout();
        } else {
          toast.error("Logout unsuccessful");
          this.props.logout();
        }
      });
  }

  render() {
    const { hasAuthToken } = this.props;
    return null;
    return hasAuthToken ? (
      <LayoutSplashScreen />
    ) : (
      <Redirect to="/auth/login" />
    );
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
