import axios from "axios";

export const SETTINGS_URL = "https://www.api.sendatradie.com/settings";

export function getRolePermissions() {
  return axios.get(`https://www.api.sendatradie.com/settings/getRolePermissions`);
}

export function editRolePermissions(data) {
  return axios.post(`https://www.api.sendatradie.com/settings/editRolePermissions`, data);
}
