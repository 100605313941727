import React, { useRef } from "react";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { DateRangePicker } from "react-date-range";
import moment from "moment";

export default class CustomDateRangePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendarRef: React.createRef(),
      endRef: React.createRef(),
      open: false,
      date: [
        {
          startDate: moment().subtract(
            props.oneYearRange
              ? 365
              : props.oneWeekRange
              ? 7
              : props.oneDayRange
              ? 1
              : 30,
            "days"
          )._d,
          endDate: new Date(),
          key: "selection",
        },
      ],
    };
  }

  componentDidMount() {
    document.body.addEventListener("click", this.onClickOnPage, true);
    this.props.setDateToSend({
      start: moment(this.state.date[0].startDate).format("yyyy-MM-DD"),
      end: moment(this.state.date[0].endDate).format("yyyy-MM-DD"),
    });
    return () => {
      document.body.removeEventListener("click", this.onClickOnPage, true);
    };
  }

  handleOnclick = () => {
    if (this.state.open) {
      this.setState({ open: false });
    } else {
      this.setState({ open: true }, function() {
        this.scrollToRight();
      });
    }
  };

  onClickOnPage = (e) => {
    if (!this.state.calendarRef || !this.state.calendarRef.current) {
      return;
    }

    let didClickInside = false;

    let parentElement = e.target;
    while (parentElement) {
      if (parentElement === this.state.calendarRef.current) {
        didClickInside = true;
        break;
      }

      parentElement = parentElement.parentElement;
    }

    if (didClickInside) {
      return;
    }
    this.setState({ open: false });
  };

  // componentDidUpdate() {
  //   //hideconsole.log(this.state);
  // }
  scrollToRight() {
    ////hideconsole.log("scroll");
    this.endRef?.current?.scrollIntoView();
  }

  render() {
    return (
      <div ref={this.state.calendarRef} className={this.props.className}>
        <small className="form-text text-muted">
          <b>Start Date</b> To <b>End Date</b>
        </small>
        <div
          onClick={this.handleOnclick}
          style={{ display: "flex", cursor: "pointer" }}
        >
          <input
            style={{ cursor: "pointer" }}
            readOnly
            type="text"
            className="form-control"
            name="DateRange"
            placeholder=""
            value={`${moment(
              this.props?.displayDates?.start || this.state.date[0].startDate
            ).format("DD-MM-yyyy")} / ${moment(
              this.props?.displayDates?.end || this.state.date[0].endDate
            ).format("DD-MM-yyyy")}`}
            // icon={DateRangeIcon}
          />
          <span>
            {" "}
            <DateRangeIcon
              style={{
                position: "absolute",
                marginLeft: "-2rem",
                marginTop: "10px",
              }}
            />
          </span>
        </div>
        <div className="d-flex flex-row">
          {this.state.open ? (
            <DateRangePicker
              className="rdrDateRangePickerWrapperCustom"
              // popperModifiers={{
              //   preventOverflow: {
              //     enabled: true,
              //   },
              // }}
              // popperProps={{
              //   positionFixed: true, // use this to make the popper position: fixed
              // }}
              onChange={(e) => {
                // //hideconsole.log(e);
                // //hideconsole.log("On change");
                //hideconsole.log(e.selection);
                this.setState({ date: [e.selection] });
                this.props.setDateToSend({
                  start: moment(e.selection.startDate).format("yyyy-MM-DD"),
                  end: moment(e.selection.endDate).format("yyyy-MM-DD"),
                });

                (moment(e.selection.startDate).format("yyyy-MM-DD") !=
                  moment(e.selection.endDate).format("yyyy-MM-DD") ||
                  moment(e.selection.endDate).unix() ==
                    moment()
                      .set("hour", 23)
                      .set("minute", 59)
                      .set("second", 59)
                      .unix() ||
                  moment(e.selection.endDate).unix() ==
                    moment()
                      .subtract(1, "d")
                      .set("hour", 23)
                      .set("minute", 59)
                      .set("second", 59)
                      .unix()) &&
                  this.handleOnclick();
              }}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={this.state.date}
              direction="vertical"
              inputRanges={[]}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
